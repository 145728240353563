@import '../../styles/customMediaQueries.css';
@import '../../styles/gridDefaults.scss';

.lastPurchasedContainer {
  padding: 0 22px 0 22px;
  @media (--viewportMedium) {
    padding: unset;
  }

  .badge {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    padding: 8px;
    font-size: 13px;
    font-weight: var(--fontWeightSemiBold);
    border-radius: 6px;
    .date {
      font-weight: var(--fontWeightBold);
    }
  }
}
